import { createRequest } from "@app/services/api/requestCreator";
import { FORMDATA_FIELD_NAMES } from "@ea/shared_types/next/ea.consts";
import { getEndpoint } from "@ea/shared_types/next/ea.endpoints";

export const getSteps = createRequest(getEndpoint("steps.sub.get"));
export const createStep = createRequest(getEndpoint("steps.sub.create"));
export const editStep = createRequest(getEndpoint("steps.sub.update"), {
  pathParams: (params) => ({ id: params.id! }),
});
export const editSteps = createRequest(getEndpoint("steps.sub.updateAll"));
export const deleteStep = createRequest(getEndpoint("steps.sub.delete"));
export const deleteSteps = createRequest(getEndpoint("steps.sub.deleteMany"));
export const deleteManyStep = createRequest(getEndpoint("steps.sub.deleteMany"));
export const pasteStep = createRequest(getEndpoint("steps.sub.paste"));
export const moveStep = createRequest(getEndpoint("steps.sub.move"));

export const uploadTemporaryFile = createRequest(getEndpoint("steps.sub.uploadTemporaryFile"), {
  useContentType: false,
  bodyBuilder: (params) => {
    const formData = new FormData();
    if (params && params.files) {
      params.files.forEach((file) =>
        formData.append(FORMDATA_FIELD_NAMES.stepFiles, file, file.name),
      );
    }
    return formData;
  },
});

export const downloadStepFile = createRequest(getEndpoint("steps.sub.downloadFile"), {
  pathParams: (params) => {
    console.log(params);
    return { id: params.stepId };
  },
  fileRequest: true,
});
