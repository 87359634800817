import { signOut } from "@app/auth";
import ClientUserError from "@ea/shared_types/ea.errors";
import { EndpointType } from "@ea/shared_types/next/ea.endpoints";
import qs from "qs";
import { config } from "../../config";
import { IS_SERVER_CONTEXT } from "../../utils/platform";
import { ErrorType, isUserError, unpackError } from "./helpers/error";

export { getEndpoint, getEndpointWithClientParams } from "@ea/shared_types/next/ea.endpoints";

interface CreateOptions<RequestType> {
  public: boolean;
  fileRequest: boolean;
  withFileName?: boolean;
  useContentType: boolean;
  contentType: string;
  bodyBuilder?: (params: RequestType) => BodyInit | undefined;
  pathParams?: (params: RequestType) => Record<string, string | number>;
  pathCreator?: (path: string, params: RequestType & { id?: number }) => string;
  transformParams: (params: RequestType) => RequestType;
  next?: NextFetchRequestConfig;
  rawResponseText?: boolean;
}

type ExtractParam<Path, NextPart> = Path extends `:${infer Param}`
  ? Record<Param, string | number> & NextPart
  : NextPart;

type ExtractParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? ExtractParam<Segment, ExtractParams<Rest>>
  : ExtractParam<Path, object>;

const defaultCreateOptions = {
  public: false,
  fileRequest: false,
  useContentType: true,
  contentType: "application/json",
  // eslint-disable-next-line
  transformParams: (params: any) => params,
  rawResponseText: false,
};

const buildQuery = <T>(parameters: T) => {
  // EA-504 Json.parse(Json.stringify) is used because qs cannot handle Dayjs object properly
  return qs.stringify(JSON.parse(JSON.stringify(parameters)));
};

export const createRequest = <
  T extends EndpointType,
  RequestType extends T["requestType"],
  ResponseType extends T["responseType"],
>(
  endpoint: T,
  options?: Partial<CreateOptions<RequestType>>,
) => {
  const method = endpoint.method;

  const requestOptions: CreateOptions<RequestType> = {
    ...defaultCreateOptions,
    ...options,
  };

  const prepareEndpoint = (params: RequestType, options?: Partial<CreateOptions<RequestType>>) => {
    if (requestOptions?.pathCreator) {
      return requestOptions.pathCreator(endpoint.path, params);
    } else {
      const pathRequestParams = requestOptions.pathParams
        ? requestOptions.pathParams(params)
        : params;
      const pathParams = options?.pathParams ? options.pathParams(params) : pathRequestParams;
      return Object.entries(pathParams).reduce(
        (path, [key, value]) => path.replaceAll(`:${key}`, `${value}`),
        endpoint.path,
      );
    }
  };

  const request = async (
    params?: T["requestType"] & ExtractParams<T["path"]>,
    authToken?: string | null,
    options?: Pick<CreateOptions<RequestType>, "next" | "pathParams">,
  ): Promise<ResponseType & { error?: ErrorType }> => {
    const finalParams = params ? requestOptions.transformParams(params) : ({} as T["requestType"]);
    const headers = new Headers();

    if (requestOptions.useContentType) {
      headers.set("Content-Type", requestOptions.contentType);
    }

    if (!requestOptions.public) {
      if (!authToken) throw new ClientUserError("Authorization token required");
      headers.set("Authorization", authToken);
    }

    if (requestOptions.fileRequest) {
      headers.set("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
    }

    const url = `${config.getApiPath()}/${prepareEndpoint(finalParams, options)}`;
    const fetchOptions: RequestInit = {
      method,
      headers,
      next: { revalidate: 60, ...requestOptions.next, ...options?.next },
    };

    if (method === "GET") {
      const queryParams = Object.keys(finalParams).length > 0 ? `?${buildQuery(finalParams)}` : "";

      const response = await fetch(`${url}${queryParams}`, fetchOptions);
      return handleResponse(response, requestOptions);
    } else {
      fetchOptions.body = requestOptions.bodyBuilder
        ? requestOptions.bodyBuilder(finalParams)
        : requestOptions.fileRequest
          ? qs.stringify(finalParams)
          : JSON.stringify(finalParams);

      const response = await fetch(url, fetchOptions);
      return await handleResponse(response, requestOptions);
    }
  };

  const handleResponse = async (response: Response, options: CreateOptions<RequestType>) => {
    if (response.status < 400) {
      try {
        if (requestOptions.fileRequest) {
          if (requestOptions.withFileName) {
            const contentDisposition = response.headers.get("Content-Disposition");
            let filename = "unknown";
            if (contentDisposition) {
              const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
              if (filenameMatch) {
                filename = filenameMatch[1];
              }
            }
            return response.blob().then((blob) => ({ blob, filename }));
          }
          return await response.blob();
        }
        const responseTest = await response.text();
        if (!responseTest) return null;

        if (options.rawResponseText) return responseTest;

        return JSON.parse(responseTest);
      } catch (error) {
        const message = error instanceof Error ? error.message : "An unexpected error occurred";
        throw new Error(`Response parse error: ${message}`);
      }
    }

    if (response.status === 403) {
      return await signOut();
    }
    if (response.status === 503) {
      throw new Error("Connection error");
    } else if (response.status >= 500) {
      throw new Error("Server error");
    } else if (response.status >= 400) {
      const error = unpackError(await response.json());

      if (IS_SERVER_CONTEXT || error.code === "INVALID_TOKEN") {
        console.error(error);
        return { error };
      }
      throw isUserError(error) ? new ClientUserError(error.message) : new Error(error.message);
    }
  };

  return request;
};
