import { createRequest, getEndpoint } from "../requestCreator";

export const getIssueTrackingConfigurations = createRequest(
  getEndpoint("issueTrackingTools.sub.configurations.sub.get"),
);

export const createIssueTrackingConfigurations = createRequest(
  getEndpoint("issueTrackingTools.sub.configurations.sub.create"),
);

export const updateIssueTrackingConfigurations = createRequest(
  getEndpoint("issueTrackingTools.sub.configurations.sub.update"),
);

export const fetchIssueTrackingConfigurations = createRequest(
  getEndpoint("issueTrackingTools.sub.configurations.sub.fetch"),
);

export const getIssueTrackingRules = createRequest(
  getEndpoint("issueTrackingTools.sub.rules.sub.get"),
);

export const getIntegrationRulesCount = createRequest(
  getEndpoint("issueTrackingTools.sub.rules.sub.count"),
);

export const updateIssueTrackingRule = createRequest(
  getEndpoint("issueTrackingTools.sub.rules.sub.update"),
);

export const createIssueTrackingRule = createRequest(
  getEndpoint("issueTrackingTools.sub.rules.sub.create"),
);

export const deleteIssueTrackingRule = createRequest(
  getEndpoint("issueTrackingTools.sub.rules.sub.delete"),
);

export const getIssueTrackingTools = createRequest(getEndpoint("issueTrackingTools.sub.get"));

export const createIssueTrackingTools = createRequest(getEndpoint("issueTrackingTools.sub.create"));

export const updateIssueTrackingTools = createRequest(getEndpoint("issueTrackingTools.sub.update"));

export const deleteIssueTrackingTools = createRequest(getEndpoint("issueTrackingTools.sub.delete"));

export const CreateADWorkItem = createRequest(
  getEndpoint("issueTrackingTools.sub.azureDevops.sub.createWorkitem"),
);

export const getItsProjects = createRequest(
  getEndpoint("issueTrackingTools.sub.azureDevops.sub.projects"),
);

export const getWorkItemTypes = createRequest(
  getEndpoint("issueTrackingTools.sub.azureDevops.sub.workitemTypes"),
);

export const exportProjectsToTestPlans = createRequest(
  getEndpoint("issueTrackingTools.sub.integration.sub.export"),
);

export const getIterations = createRequest(
  getEndpoint("issueTrackingTools.sub.azureDevops.sub.iterations"),
);

export const getAreas = createRequest(getEndpoint("issueTrackingTools.sub.azureDevops.sub.areas"));

export const getTestCases = createRequest(
  getEndpoint("issueTrackingTools.sub.integration.sub.testCases"),
);

export const getTestPlans = createRequest(
  getEndpoint("issueTrackingTools.sub.integration.sub.testPlans"),
);
