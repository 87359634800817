import { createRequest, getEndpoint } from "../requestCreator";

export const getExecutionLogsStats = createRequest(getEndpoint("logs.sub.execution.sub.stats"));
export const getProjectExecutionLogsStats = createRequest(
  getEndpoint("logs.sub.execution.sub.project"),
);
export const getLogs = createRequest(getEndpoint("logs.sub.execution.sub.get"));
export const getLogsCount = createRequest(getEndpoint("logs.sub.execution.sub.count"));
export const getLogOverview = createRequest(getEndpoint("logs.sub.execution.sub.overview"));

export const getCapturedFiles = createRequest(getEndpoint("logs.sub.execution.sub.capturedFiles"));

export const downloadCapturedFile = createRequest(
  getEndpoint("logs.sub.execution.sub.downloadCapturedFile"),
  {
    fileRequest: true,
  },
);

export const terminateExecution = createRequest(getEndpoint("execution.sub.terminate"));

export const getStepsLogs = createRequest(getEndpoint("logs.sub.step.sub.get"));

export const getAgregatedLogs = createRequest(getEndpoint("logs.sub.job.sub.aggregated.sub.get"));
