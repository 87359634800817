import { useSessionToken } from "@app/hooks/useSession";
import { FirstArgumentType } from "@ea/shared_types/next/ea.utils.types";
import {
  UseMutationOptions,
  UseQueryOptions,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useRef } from "react";
import { signOut } from "../../auth";
import { createRequest } from "./requestCreator";

type QueryOptions<ResponseType> = Partial<Omit<UseQueryOptions<ResponseType, Error>, "queryFn">>;

export const createQuery = <
  T extends ReturnType<typeof createRequest>,
  P extends Parameters<T>,
  RequestType extends P[0],
  ResponseType extends Awaited<ReturnType<T>>,
>(
  request: T,
  defaultQueryOptions?: QueryOptions<ResponseType>,
) => {
  return <P extends RequestType>(params?: P, queryOptions?: QueryOptions<ResponseType>) => {
    const authToken = useSessionToken();
    const calculatedQueryKey = queryOptions?.queryKey
      ? [...(queryOptions?.queryKey || [])]
      : [...(defaultQueryOptions?.queryKey || []), params];

    return useQuery({
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
      ...defaultQueryOptions,
      ...queryOptions,
      enabled: typeof queryOptions?.enabled === "undefined" ? true : queryOptions?.enabled,
      queryKey: calculatedQueryKey,
      queryFn: async () => {
        const response = await request(params, authToken);
        if (response.error?.code === "INVALID_TOKEN") {
          await signOut();
        }
        return response;
      },
    });
  };
};

export const createMutation = <
  T extends ReturnType<typeof createRequest>,
  ResponseType extends Awaited<ReturnType<T>>,
>(
  request: T,
  mutationOptions?: Omit<
    UseMutationOptions<ResponseType, Error, FirstArgumentType<Awaited<T>>>,
    "mutationFn"
  >,
  queryKey?: string[] | undefined,
) => {
  return () => {
    const authToken = useSessionToken();
    const counter = useRef(0);
    const queryClient = useQueryClient();

    return useMutation({
      ...mutationOptions,
      mutationKey: [authToken, ...(mutationOptions?.mutationKey || [])],
      mutationFn: async (params) => {
        const response = await request(params, authToken);
        if (response?.error?.code === "INVALID_TOKEN") {
          await signOut();
        }
        return response;
      },
      onMutate: () => {
        counter.current++;
      },
      onSettled: async () => {
        counter.current--;
        if (counter.current === 0 && queryKey) {
          await queryClient.invalidateQueries({ queryKey });
        }
      },
    });
  };
};
