import Cookies from "js-cookie";
import { SESSION_COOKIE } from "../consts";
import { Session } from "../types";

export const useSession = () => {
  try {
    const value = Cookies.get(SESSION_COOKIE);
    return { data: value ? (JSON.parse(value) as Session) : undefined };
  } catch (e) {
    return {};
  }
};

export const useCurrentUserId = () => {
  const session = useSession();
  return session?.data?.user.id;
};

export const useSessionToken = () => {
  const session = useSession();
  return session.data?.authToken;
};
