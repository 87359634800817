import { createRequest } from "@app/services/api/requestCreator";
import { getEndpoint } from "@ea/shared_types/next/ea.endpoints";

export const logsScreenshotEndpoint = getEndpoint("logs.sub.step.sub.screenshot");

export const executionStepLogs = createRequest(
  getEndpoint("logs.sub.step.sub.executionScreenshots"),
);

export const getExecutionStepScreenshots = createRequest(
  getEndpoint("logs.sub.step.sub.screenshot"),
);
