import { createRequest, getEndpoint } from "../requestCreator";

export const getPlatforms = createRequest(getEndpoint("platforms.sub.administration"));
export const getPlatformFile = createRequest(getEndpoint("platforms.sub.downloadscript"), {
  rawResponseText: true,
});
export const getExtensionFile = createRequest(getEndpoint("platforms.sub.downloadExtension"), {
  fileRequest: true,
  withFileName: true,
});
